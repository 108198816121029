/* Reset y estilos generales */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  line-height: 1.5;
  color: #1a1a1a;
}

.min-h-screen {
  min-height: 100vh;
}

/* Hero Section */
.hero {
  background: linear-gradient(to right, #ebf4ff, #ebf5ff);
  padding: 5rem 0;
}

.container {
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 1rem;
}

.text-center {
  text-align: center;
}

.hero h1 {
  font-size: 3.75rem;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 1rem;
}

.hero h1 span {
  display: block;
  color: #4f46e5;
  margin-top: 0.5rem;
}

.hero p {
  max-width: 48rem;
  margin: 1rem auto;
  color: #6b7280;
  font-size: 1.125rem;
}

/* Botones */
.button-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.button {
  display: inline-flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  border-radius: 0.375rem;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.2s;
}

.button-primary {
  background-color: #4f46e5;
  color: white;
  border: none;
}

.button-primary:hover {
  background-color: #4338ca;
}

.button-secondary {
  background-color: white;
  color: #4b5563;
  border: 1px solid #d1d5db;
}

.button-secondary:hover {
  background-color: #f9fafb;
}

/* Pain Points Section */
.pain-points {
  padding: 4rem 0;
  background-color: white;
}

.pain-points-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.pain-point-card {
  background-color: #fff1f2;
  padding: 1.5rem;
  border-radius: 0.5rem;
}

.pain-point-card h3 {
  color: #b91c1c;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.pain-point-card p {
  color: #dc2626;
}

/* Benefits Section */
.benefits {
  padding: 4rem 0;
  background-color: #f9fafb;
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2.5rem;
  margin-top: 2.5rem;
}

.benefit-card {
  position: relative;
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.benefit-icon {
  position: absolute;
  margin-top: -3.0rem;
  background-color: #4f46e5;
  padding: 0.75rem;
  border-radius: 0.375rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: inline-flex;
}

.benefit-icon svg {
  width: 1.5rem;
  height: 1.5rem;
  color: white;
}

/* Services Section */
.services {
  padding: 4rem 0;
  background-color: white;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2.5rem;
  margin-top: 2.5rem;
}

.service-card {
  padding-top: 1.5rem;
}

.service-content {
  background-color: #f9fafb;
  border-radius: 0.5rem;
  padding: 1.5rem;
  position: relative;
}

.service-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  background-color: #4f46e5;
  border-radius: 0.375rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: -2.5rem;
}

.service-icon svg {
  width: 1.5rem;
  height: 1.5rem;
  color: white;
}

.service-list {
  margin-top: 1rem;
  list-style: none;
}

.service-list li {
  display: flex;
  align-items: center;
  color: #6b7280;
  margin-bottom: 0.5rem;
}

.service-list li svg {
  width: 1rem;
  height: 1rem;
  color: #10b981;
  margin-right: 0.5rem;
}

/* Testimonials Section */
.testimonials {
  padding: 4rem 0;
  background-color: #f9fafb;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2.5rem;
}

.testimonial-card {
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.testimonial-quote {
  font-style: italic;
  color: #4b5563;
  margin-bottom: 1rem;
}

.testimonial-author {
  font-weight: 600;
  color: #1a1a1a;
}

.testimonial-role {
  color: #6b7280;
}

/* Contact Section */
.contact {
  padding: 4rem 0;
  background-color: #4f46e5;
  color: white;
}

.contact h2 {
  color: white;
  margin-bottom: 1rem;
}

.contact p {
  color: #e0e7ff;
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.contact .button {
  background-color: white;
  color: #4f46e5;
}

.contact .button:hover {
  background-color: #f9fafb;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
  }

  .button-container {
    flex-direction: column;
    align-items: center;
  }

  .button {
    width: 100%;
    max-width: 300px;
    justify-content: center;
  }

  .services-grid,
  .benefits-grid,
  .testimonials-grid {
    grid-template-columns: 1fr;
  }
}

/* Utilidades */
.section-title {
  font-size: 2.25rem;
  font-weight: 800;
  color: #1a1a1a;
  margin-bottom: 2rem;
}

.text-lg {
  font-size: 1.125rem;
}

.font-medium {
  font-weight: 500;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

/* Animaciones */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 0.6s ease-out;
}